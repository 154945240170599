.header__left > img{
    height :40px;
}
.header{
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;

    position: sticky;
    background-color: white;
    z-index: 100;
    top:0;
    box-shadow: 0px 5px 8px -9px rgba(0,0,0,75);
}

.header__left
{
    display: flex;
    align-items:center
}

.header__input
{
    
    align-items: center;
    background-color: #eff2f5;
    padding:10px;
    margin-left:10px;
    border-radius: 999px;
}

.header__input > input
{

    border:none;
    background-color: transparent;
    outline-width: 0;

}

.header__center
{
    display:flex;
    flex:1;
    justify-content: center;
}

.header__option
 {
    display: flex;
    align-items: center;
    padding: 0 30px;
    cursor: pointer;
 
  }

.header__option > .MuiSvgIcon-root
{
    color: gray;
}
.header__option:hover > .MuiSvgIcon-root
{
    color: skyblue;
}

.header__option--active > .MuiSvgIcon-root
{
    color: gray;
}

.header__option--active > .MuiSvgIcon-root
{
    color: skyblue;
}

.header__option--active {
    border-bottom: 4px solid #2e81f4;
  }
  

  
  .header__option:hover {
    background-color: #eff2f5;
    border-radius: 10px;
    align-items: center;
    padding: 0 30px;
    border-bottom: 4px solid #2e81f4;
  }
  
  .header__info {
    display: flex;
    align-items: center;
  }
  
  .header__info > h4 {
    margin-left: 10px;
  }
  
  .header__right {
    display: flex;
  }
  
  .like2 {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/theideasplanet-16b29.appspot.com/o/icons%2Fthum.png?alt=media&token=690a6c84-7012-4e92-a77a-599597a7293a');
    background-size: 97px 1700px;
    background-repeat: no-repeat;
    display: inline-block;
    width: 18px;
    height: 18px;
    background-position: -49px -1388px;
    margin-right: 6px;
  }
  
  
  .like2.blue {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/theideasplanet-16b29.appspot.com/o/icons%2Fthum.png?alt=media&token=690a6c84-7012-4e92-a77a-599597a7293a);
    background-size: 97px 1700px;
    background-repeat: no-repeat;
    display: inline-block;
    width: 18px;
    height: 18px;
    background-position: -49px -1369px;
    margin-right: 6px;
  }