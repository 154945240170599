.home-screen {
  position: relative;
  margin-top: 10%;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.home-screen img {
  margin-bottom: 2%;
}
.home-screen .search-box {
  position: relative;
  height: 40px;
  margin-top: 2%;
  
  border-radius: 10px;
  border: 1px solid #999 !important;
}
.messageSender {
 
  margin-top: 10px;
  flex-direction: column;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 5px 7px -7px rgba(0, 0, 0, 0.75);
  width: 100%;
}

.messageSender__top {
 
  border-bottom: 1px solid #eff2f5;
  padding: 5px;
}

.messageSender__top > form {
  flex: 1;
 
}

.messageSender__top > form > input {
  outline-width: 0;
  border: none;
  padding: 5px 20px;
  margin: 0 10px;
  border-radius: 999px;
  background-color: #eff2f5;
}

.button {
  display: none;
}
.messageSender__top > form > button {
  display: none;
}

.messageSender__input {
  flex: 1;
}

.messageSender__bottom {

  justify-content: space-evenly;
}

.messageSender__option {
  padding: 20px;
  
  align-items: center;
  color: gray;
  margin: 5px;
}

.messageSender__option > h3 {
  font-size: medium;
  margin-left: 10px;
  cursor: pointer;
}

.messageSender__option:hover {
  background-color: #eff2f5;
  border-radius: 20px;
}
.home-screen .search-box form {
  position: relative;
  width: 80%;
}
.home-screen .search-box form input {
  width: 100%;
  height: 30px;
  border: 0;
  outline: none;
  padding: 2px 0;
}
.home-screen .search-box:hover {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
}
.home-screen .search-box .fa {
  position: relative;
  margin: 0 1%;
  font-size: 1.2rem;
  color: #444;
}
.home-screen .search-box .fa.fa-close,
.home-screen .search-box .fa.fa-microphone {
  cursor: pointer;
}
