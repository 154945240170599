
.app {
    background-color: #f1f2f5;
  }
  
  .app__body {
    display: flex;
  }
  
/*
  body {
    background-color: #e9ebee;
}

.app__page {
    display: flex;
}

.app__posts {
    display: flex;
    justify-content: center;
}

*/