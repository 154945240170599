.search-page-sub-header li {
  cursor: pointer;
}
.search-page-sub-header a,
.search-page-sub-header .fa {
  color: #5f6368;
  font-size: 0.89rem;
  text-decoration: none;
}
.search-page-sub-header li.active {
  border-bottom: 2px solid #000;
}
.search-page-sub-header li.active a,
.search-page-sub-header li.active a i {
  font-weight: bold;
  color: #000;
}
