.sidebarRow {
    display: flex;
    align-items: left;
    padding: 10px;
    cursor: pointer;
  }
  
  .sidebarRow:hover {
    background-color: lightgray;
    border-radius: 10px;
  }
  
  .sidebarRow > h4 {
    margin-left: 10px;
    font-weight: 100;
  }
  
  .sidebarRow > .MuiSvgIcon-root {
    font-size: large;
    color: #2e81f4;
  }
  