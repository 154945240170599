

.login > button {
  background-color: #2e81f4;
  
 
  color: #eff2f5;
  font-weight: 800;
}

.login > label {
 
}

.login > input {
  
 
}

.login > button:hover {
  background-color: #fff;
  color: #2e81f4;
}



.tname {
 
 width: 500px;
  height: 100px;
 
  
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  
  margin: auto;
  
  
  
}

.left {
 
  width: 500px;
  height: 100px;
 
  
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  
  margin: auto;
  
  
}
.login >img
{
 
  
 



}
.header__left
{
    display: flex;
    align-items:center
}
.tt
{
  text-decoration: none;
 
}
.space::after {
  content: " ";
  white-space: pre;
}
.login {

   
  position: absolute;
   top: 0; 
   right: 0;
    width: 900px; 
    text-align:right;  
    margin-top:50px;
    margin-right: 50px;
 

}

.footer {

   
  position: absolute;
   bottom: 0; 
 
    width: 100%; 
    text-align:center;  
    margin-bottom:50px;
  

}
.right {
 
 
}