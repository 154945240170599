.post {
    width: 100%;
    margin-top: 15px;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0px 5px 7px -7px rgba(0, 0, 0, 0.75);
  }
  
  .post__top {
    display: flex;
    position: relative;
    align-items: center;
    padding: 15px;
    cursor: pointer;
  }
  
  .post__avatar {
    margin-right: 10px;
  }
  
  .post__topInfo > h3 {
    font-size: medium;
  }
  
  .post__bottom {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 15px 25px;
  }
  .post__image > img {
    width: 100%;
  }
  
  .post__options {
    padding-top: 10px;
    border-top: 1px solid lightgray;
    display: flex;
    justify-content: space-evenly;
    font-size: medium;
    color: gray;
    cursor: pointer;
    padding: 15px;
  }
  
  .post__option {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    flex: 1;
  }
  
  .post__option > p {
    margin-left: 10px;
  }
  
  .post__option:hover {
    background-color: #eff2f5;
    border-radius: 10px;
  }
  
/* BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB*/



.post__image {
  width: 100%;
  object-fit: contain;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.post__text {
  font-weight: normal;
  padding-left: 12px;
  padding-bottom: 10px;
  padding-top: 5px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.comments__show .container__comments {
  display: flex;
  flex-direction: row;
  background-color: #f2f3f5;
  border-radius: 15px;
  height: auto;
  width: 300px;
  margin-bottom: 20px;
}

.comments__show {
  display: flex;
  flex-direction: row;
}

.comments__show.myself {
  display: flex;
  flex-direction: row;
  border-left: 2px solid #4080ff;
}

.container__comments p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  line-height: 16px;
  margin-top: 10px;
  margin-left: 15px;
  margin-bottom: 15px;
  margin-right: 10px;
  display: flex;
  flex-wrap: wrap;
  overflow-wrap: break-word;
}

.container__comments span {
  color: #385898;
  font-weight: 700;
}

.post__comments {
  padding:20px;
}

.post__header {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-left: 10px;
}

.post__header > h3 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 750;
  font-size: 14px;
  color: #385898;
}

.post__avatar {
  margin-right: 10px;
}

.post__input {
  flex: 1;
  border: none;
  padding: 10px;
  border-top: 10px solid lightgray;
}

.post__like {
  background-image: url(https://firebasestorage.googleapis.com/v0/b/theideasplanet-16b29.appspot.com/o/icons%2Fthum.png?alt=media&token=690a6c84-7012-4e92-a77a-599597a7293a);
  background-size: 97px 1700px;
  background-repeat: no-repeat;
  display: inline-block;
  width: 18px;
  height: 18px;
  background-position: -57px -1448px;
  margin-left: 10px;
  margin-bottom : 10px;
  margin-top: 5px;
  margin-right: -2px;
}

.post__likeandlove {
  display: flex;
  flex-direction: row;
}

.post__likeandlove p {
  margin-left: 3px;
  margin-top:3px;
}

.post__heart {
  
  background-image: url(https://firebasestorage.googleapis.com/v0/b/theideasplanet-16b29.appspot.com/o/icons%2Fheart.png?alt=media&token=636e2924-8e47-4f7e-87fb-8d7dc3246016);
  background-size: 97px 1700px;
  background-repeat: no-repeat;
  display: inline-block;
  width: 18px;
  height: 18px;
  background-position: -76px -1448px;
  margin-top: 4px;
}

.post__verified {
  background-image: url(https://facebook.com/rsrc.php/v3/y4/r/mSeZZaYLCYt.png);
  background-repeat: no-repeat;
  background-size: 97px 1700px;
  background-position: -29px -1646px;
  height: 12px;
  margin-left: 3px;
  vertical-align: -1px;
  width: 12px;
}

.post > hr {
  margin-bottom: 10px;
  color: gray;
  opacity: 0.4;
  margin-right: 10px;
  margin-left: 10px;
}

.like2 {
  background-image: url('https://firebasestorage.googleapis.com/v0/b/theideasplanet-16b29.appspot.com/o/icons%2Fthum.png?alt=media&token=690a6c84-7012-4e92-a77a-599597a7293a');
  background-size: 97px 1700px;
  background-repeat: no-repeat;
  display: inline-block;
  width: 18px;
  height: 18px;
  background-position: -49px -1388px;
  margin-right: 6px;
}


.like2.blue {
  background-image: url(https://firebasestorage.googleapis.com/v0/b/theideasplanet-16b29.appspot.com/o/icons%2Fthum.png?alt=media&token=690a6c84-7012-4e92-a77a-599597a7293a);
  background-size: 97px 1700px;
  background-repeat: no-repeat;
  display: inline-block;
  width: 18px;
  height: 18px;
  background-position: -49px -1369px;
  margin-right: 6px;
}

.textforlike.bluetextforlike {
  color: #4080FF;
}

.post__likeoptions {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.like {
  display: flex;
  flex-direction: row;
  height: 28px;
  width: 150px;
  justify-content: center;
  padding-top: 6px;
}

.like:hover {
  background-color: #e9e9e9;
}

.like > h3 {
  font-size: 13px;
  color: #606770;
  font-weight: 600;
  margin-top: 2px;
  font-family: Arial, Helvetica, sans-serif;
}

.like > h3:hover {
  cursor: pointer
}

.post__likeoptions:hover {
  cursor: pointer;
}

.comment:hover {
  cursor: pointer;
}

.share:hover {
  cursor: pointer;
}

.comment2 {
  background-image: url(https://facebook.com/rsrc.php/v3/y4/r/mSeZZaYLCYt.png);
  background-size: 97px 1700px;
  background-repeat: no-repeat;
  display: inline-block;
  width: 18px;
  height: 18px;
  background-position: -49px -1350px;
  margin-right: 6px;
}

.comment {
  display: flex;
  flex-direction: row;
  height: 28px;
  width: 150px;
  justify-content: center;
  padding-top: 6px;
}

.comment:hover {
  background-color: #e9e9e9;
}

.comment > h3 {
  font-size: 13px;
  color: #606770;
  font-weight: 600;
  margin-top: 2px;
  font-family: Arial, Helvetica, sans-serif;
}

.comment > h3:hover {
  cursor: pointer
}

.share {
  display: flex;
  flex-direction: row;
  height: 28px;
  width: 150px;
  justify-content: center;
  padding-top: 6px;
}

.share > h3 {
  font-size: 13px;
  color: #606770;
  font-weight: 600;
  margin-top: 2px;
  font-family: Arial, Helvetica, sans-serif;
}

.share > h3:hover {
  cursor: pointer
}

.share2 {
  background-image: url(https://facebook.com/rsrc.php/v3/y4/r/mSeZZaYLCYt.png);
  background-size: 97px 1700px;
  background-repeat: no-repeat;
  display: inline-block;
  width: 18px;
  height: 18px;
  background-position: -68px -1407px;
  margin-right: 6px;
}

.share:hover {
  background-color: #e9e9e9;
}

.transparent__submit {
  display: none;
}

.commentBox {
  display: flex;
  flex-direction: row;
}

.post__avatar2 {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom:20px;
}

.commentInputBox {
  border-radius: 20px;
  border: 1px solid gray;
  outline: none;
  background-color: #F2F3F5;
  height: 27px !important;
  width: 417px;
  padding: 5px;
}

.commentInputBox::-webkit-input-placeholder {
  color: gray;
}

.pressEnterToPost {
  color: rgb(153, 153, 153);
  font-size: 9px;
  font-family: Arial, Helvetica, sans-serif;
  margin-left:70px;
  margin-top: -14px;
  margin-bottom: 8px;
  display:none
}

  